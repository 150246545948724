<template>
  <div class="pipe-wrapper">
    <div class="site-canvas" id="game-app">
      <div class="score">
        <span v-if="activeLevelData">Moves: {{activeLevelData.moves}}</span>
      </div>
      <div class="attempts" v-if="activeLevelData"
           :class="{'solved': activeLevelData.solved, 'show': activeLevelData.attempts > 0}">
        {{activeLevelData.attempts}}
      </div>
      <div class="grid" v-if="activeLevelData" :class="{'solved': activeLevelData.solved}">
        <pipe-block v-for="(block, index) in activeLevelData.config"
               :key="`block-${index}`"
               :block.sync="block" v-on:check="check()"></pipe-block>
        <div class="next-button" v-if="activeLevelData.solved" @click="next()">
          Next Level
        </div>
      </div>
      <div class="completed" v-if="!activeLevelData">
        You Won!
        <div class="restart" @click="restart()">
          Again!
        </div>
      </div>
      <transition name="fade">
        <div class="loaded" v-if="!loaded"></div>
      </transition>
    </div>
  </div>
</template>
<script type="text/babel">
import PipeBlock from './PipeBlock.vue';

export default {
  name: 'Fac3dPipe',
  components: {
    PipeBlock,
  },
  computed: {
    activeLevelData() {
      return this.levels.data[this.activeLevel];
    },
  },
  data() {
    return {
      block: {},
      loaded: false,
      levels: {
        score: 0,
        data: [
          {
            config: [
              { start: 2, type: 0, goal: 2 },
              { start: 1, type: 1, goal: 1 },
              { start: 3, type: 0, goal: 3 },
              { start: 2, type: 2, goal: 2 },
              { start: 1, type: 0, goal: 1 },
              { start: 2, type: 2, goal: 3 },
              { start: 1, type: 0, goal: 1 },
              { start: 0, type: 0, goal: 0 },
            ],
            moves: 1,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 2, type: 0, goal: 2 },
              { start: 1, type: 1, goal: 1 },
              { start: 3, type: 2, goal: 3 },
              { start: 2, type: 2, goal: 2 },
              { start: 1, type: 0, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 0, type: 0, goal: 0 },
            ],
            moves: 2,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 0, type: 2, goal: 2 },
              { start: 1, type: 0, goal: 2 },
              { start: 0, type: 0, goal: 3 },
              { start: 3, type: 2, goal: 2 },
              { start: 1, type: 1, goal: 0 },
              { start: 0, type: 1, goal: 0 },
              { start: 1, type: 1, goal: 0 },
              { start: 0, type: 1, goal: 0 },
              { start: 3, type: 0, goal: 1 },
              { start: 1, type: 0, goal: 0 },
              { start: 0, type: 0, goal: 1 },
              { start: 3, type: 0, goal: 0 },
            ],
            moves: 18,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 0, type: 2, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 1, type: 0, goal: 3 },
              { start: 0, type: 0, goal: 2 },
              { start: 0, type: 1, goal: 1 },
              { start: 1, type: 1, goal: 1 },
              { start: 2, type: 0, goal: 0 },
              { start: 0, type: 0, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 1, type: 1, goal: 1 },
              { start: 2, type: 2, goal: 3 },
            ],
            moves: 16,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 1, type: 0, goal: 2 },
              { start: 1, type: 1, goal: 1 },
              { start: 1, type: 1, goal: 1 },
              { start: 0, type: 0, goal: 3 },
              { start: 2, type: 0, goal: 1 },
              { start: 3, type: 0, goal: 3 },
              { start: 2, type: 0, goal: 2 },
              { start: 3, type: 0, goal: 0 },
              { start: 0, type: 2, goal: 2 },
              { start: 0, type: 1, goal: 0 },
              { start: 0, type: 1, goal: 0 },
              { start: 0, type: 2, goal: 2 },
              { start: 2, type: 0, goal: 1 },
              { start: 0, type: 0, goal: 0 },
              { start: 1, type: 0, goal: 1 },
              { start: 3, type: 0, goal: 0 },
            ],
            moves: 22,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 2, type: 2, goal: 1 },
              { start: 2, type: 0, goal: 3 },
              { start: 2, type: 0, goal: 2 },
              { start: 3, type: 0, goal: 3 },
              { start: 0, type: 2, goal: 1 },
              { start: 1, type: 0, goal: 0 },
              { start: 0, type: 0, goal: 1 },
              { start: 0, type: 0, goal: 0 },
            ],
            moves: 9,
            attempts: 0,
            solved: false,
          },
          {
            config: [
              { start: 3, type: 0, goal: 2 },
              { start: 0, type: 1, goal: 1 },
              { start: 0, type: 1, goal: 1 },
              { start: 2, type: 0, goal: 3 },
              { start: 0, type: 1, goal: 0 },
              { start: 2, type: 0, goal: 2 },
              { start: 3, type: 0, goal: 3 },
              { start: 0, type: 1, goal: 0 },
              { start: 2, type: 2, goal: 0 },
              { start: 0, type: 1, goal: 0 },
              { start: 0, type: 1, goal: 0 },
              { start: 2, type: 2, goal: 0 },
              { start: 0, type: 2, goal: 1 },
              { start: 1, type: 0, goal: 0 },
              { start: 0, type: 0, goal: 1 },
              { start: 0, type: 2, goal: 3 },
            ],
            moves: 18,
            attempts: 0,
            solved: false,
          },
        ],
      },
      activeLevel: 0,
      backupLevels: [],
    };
  },
  methods: {
    check() {
      const level = this.levels.data[this.activeLevel];
      level.attempts += 1;
      level.solved = !level.config.some((b) => b.start !== b.goal);
    },
    next() {
      this.activeLevel += 1;
    },
    restart() {
      this.levels = JSON.parse(JSON.stringify(this.backupLevels));
      this.activeLevel = 0;
    },
  },
  async mounted() {
    this.backupLevels = JSON.parse(JSON.stringify(this.levels));
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
};
</script>
<style lang="scss">
.completed {
  font-size:3.6em;
}
.score {
  position:fixed;
  top:0;
  left:0;
  right:0;
  padding:15px;
  text-align:center;
  letter-spacing:2px;
  opacity:0.4;
  span {
    color: white;
  }
}
.attempts {
  position: absolute;
  //top:50%;
  left:50%;
  transform:translate3d(-50%,-50%,0);
  transition:350ms ease-in-out;
  z-index:-1;
  opacity:0;
  font-size: 22px;
  top: auto;
  bottom: -10px;
}
.attempts.show {
  opacity: 0.5;
}
.attempts.solved {
  transform:translate3d(-50%,calc(-50% + -80px),0);
}
.site-canvas {
  //position: fixed;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: auto;
  //height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  //margin-left: -15px;
  //margin-bottom: 3rem;
}
.loaded {
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background:#000;
  z-index:999;
}
.grid {
  position:relative;
  width:calc(4 * 80px);
  min-width:calc(4 * 80px);
  transition:350ms ease-in-out;
  animation:350ms fadeIn ease-in-out;
  background: rgba(0, 0, 0, 0.25);
}
//.grid.solved {
//  transform:translate3d(0,-40px,0);
//}
.blocks {
  overflow:hidden;
}
.circle-block {
  position: relative;
  width: 80px;
  height: 80px;
  float: left;
  border-radius:50%;
  transition:250ms ease-in-out;
  overflow:hidden;
  cursor: pointer;
}
.circle-block:hover {
  background:rgba(0,0,0,0.4);
}
.circle {
  margin:-36px 0 0 -36px;
  border:8px solid #fff;
  width:80px;
  height:80px;
  border-radius:40%;
  box-sizing:border-box;
}
.line {
  margin:auto;
  width:8px;
  height:80px;
  background: #fff;
}
.ball {
  margin:auto;
  width:8px;
  height:40px;
  background: #fff;
}
.ball:before {
  position: absolute;
  margin:auto;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:20px;
  height:20px;
  border-radius:50%;
  border:4px solid #fff;
  background:#000;
  content:'';
}

.next-button {
  position:absolute;
  left:0;
  right:0;
  padding:15px 30px;
  width:80%;
  border-radius:50px;
  color:#fff;
  clear:both;
  box-sizing:border-box;
  animation:350ms fadeIn ease-in-out;
  text-align:center;
  cursor:pointer;
  bottom: 0;
  margin: 0 auto;
  background: purple;
}
@keyframes fadeIn {
  0%{opacity:0;transform:scale(0.95)}
}

.restart {
  margin:40px 0 0;
  text-align:center;
  padding:15px 30px;
  background:#333;
  border-radius:50px;
  font-size:16px;
  cursor:pointer;
  color:#fff;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.pipe-wrapper {
  position: relative;
  width: 100%;
  background: black;
}
</style>
