<template>
  <div class="circle-block" v-if="block != null"
       :style="blockPosition()" @click.prevent="rotate()">
    <div class="circle" v-if="block.type === 0"></div>
    <div class="line" v-if="block.type === 1"></div>
    <div class="ball" v-if="block.type === 2"></div>
  </div>
</template>
<script>
export default {
  props: ['block'],
  methods: {
    blockPosition() {
      return {
        transform: `rotate(${this.block.start * 90}deg)`,
      };
    },
    rotate() {
      this.block.start += 1;
      if ((this.block.type === 0 || this.block.type === 2) && this.block.start > 3) {
        this.block.start = 0;
      } else if (this.block.type === 1 && this.block.start > 1) this.block.start = 0;
      this.$emit('check');
      // this.check();
    },
  },
};
</script>
