<template>
  <section class="queue-wrapper">
    <md-card md-theme="orange-card">
      <md-card-header>
        <div class="md-title" v-if="!getQueueError && !isQueueDone">
          <md-icon>hourglass_top</md-icon> Processing face...
        </div>
        <div class="md-title" v-else-if="!getQueueError && isQueueDone">
          Your model is ready! 🥑
        </div>
        <div class="md-title" v-else>
          <md-icon>error_outline</md-icon> An error occurred!
        </div>
      </md-card-header>
      <md-card-actions>
        <button class="raised-btn faded-btn"
                v-if="!isQueueDone"
                v-text="checking ? 'Checking status...' : 'Done yet?🙄'"
                @click="updateStatus">
        </button>
        <!-- <span class="text-muted">We'll also be checking for you in the background</span> -->
      </md-card-actions>
    </md-card>
    <md-card md-theme="orange-card" class="game-card" v-if="!getQueueError && !isQueueDone">
      <md-card-content>
        <p class="md-subtitle">Some fun while you wait:</p>
        <pipe-game></pipe-game>
      </md-card-content>
    </md-card>
    <br>
    <br>
    <br>
  </section>
</template>
<script type="text/babel">
import { mapGetters } from 'vuex';
import PipeGame from './PipeGame.vue';

export default {
  name: 'Fac3dQueue',
  components: {
    PipeGame,
  },
  props: {
    loading: Boolean,
  },
  computed: {
    ...mapGetters(['getJobId', 'isQueueDone', 'getQueueError', 'getFinalImage']),
    displayWaitingComponent() {
      return !this.getQueueError && !this.isQueueDone;
    },
  },
  data() {
    return {
      checking: false,
      status: '',
      polling: null,
    };
  },
  methods: {
    async updateStatus() {
      this.checking = true;
      // const res = await this.$store.dispatch('getResultFromQueue');
      // console.log('response: ', res);
      // if (res.status === 202) {
      //   await this.$store.dispatch('setAlert', {
      //     show: true,
      //     title: 'Still not done...',
      //     content: 'Your model is processing. Bear with us :|',
      //   });
      // }
      this.$store.dispatch('getResultFromQueue')
        .then((res) => {
          this.checking = false;
          // Filename is updated by vuex
          if (res.status === 202) {
            this.$store.dispatch('setAlert', {
              show: true,
              title: 'Still not done...',
              content: 'Your model is processing. Bear with us :|',
            });
          } else if (res.status === 202) {
            this.$store.dispatch('setAlert', {
              show: true,
              title: 'Your 3d Model is ready!',
              content: 'Close this and download it',
            });
          }
        }, (err) => {
          this.checking = false;
          if (err.status === 404) {
            this.$store.dispatch('setAlert', {
              show: true,
              title: 'Your job was not found',
              content: 'Your session has expired. Feel free to try again',
            });
          } else {
            this.$store.dispatch('setAlert', {
              show: true,
              title: 'There was an issue with your job',
              content: 'Uh oh, Fac3d can no longer find your file... Feel free to try again!',
            });
          }
        });
    },
    pollData() {
      const interval = 10000;
      this.polling = setInterval(() => {
        if (!this.$store.state.isQueueDone) {
          this.$store.dispatch('getResultFromQueue')
            .then((res) => {
              if (res.status === 200) clearInterval(this.polling);
            })
            .catch(() => {
              clearInterval(this.polling);
            });
        } else clearInterval(this.polling);
      }, interval);
    },
  },
  created() {
    this.$store.commit('SET_RESULT_IMAGE', null);
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>
<style lang="scss">
.queue-wrapper {
  .md-card {
    box-shadow: none !important;
    .md-card-actions {
      justify-content: center !important;
    }
  }
  .loading-container {
    margin: 0 auto;
    max-width: 50%;
  }
}
.game-card {
  position: relative;
}
.pipe-wrapper {
  min-height: 200px;
  background: transparent;
}
</style>
